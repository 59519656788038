@import 'styles'

$padding: 1.25rem
$line-height: 18px
$image-height: 32px
$min-height: 160px

.tether
  position: absolute
  border-top: 2px dashed rgba(52,87,166,1)
  box-sizing: border-box
  z-index: -1

.top-box
  @extend %block
  $border-radius: 6px

  background-color: #fff
  border-top-left-radius: $border-radius
  border-top-right-radius: $border-radius
  box-shadow: 0 0.5em 1em -0.125em rgba($black, 0.1), 0 0px 0 1px rgba($black, 0.02)
  clip-path: inset(-100% -100% 0 -100%)
  color: hsl(0, 0%, 29%)
  display: block
  padding: $padding
  min-height: $min-height - (($image-height + $line-height) / 2)

.bottom-box
  @extend %block
  $border-radius: 6px

  background-color: #fff
  border-bottom-left-radius: $border-radius
  border-bottom-right-radius: $border-radius
  box-shadow: 0 0.5em 1em -0.125em rgba($black, 0.1), 0 0px 0 1px rgba($black, 0.02)
  clip-path: inset(0 -100% -100% -100%)
  color: hsl(0, 0%, 29%)
  display: block
  padding: $padding
  min-height: $min-height

  .tagtext
    padding-top: $padding + 1.5rem

.divider-box
  position: absolute
  left: 10%
  right: 10%
  width: 80%

  .divider-pxc
    position: relative
    border-top: 1px solid #dbdbdb
    &::before
      content: ''
      position: absolute
      left: 50%
      margin-top: -1px
      height: 1px
      background-color: #fff
      width: 32px + 10px
      transform: translateX(-21px)

  .divider-content
    transform: translateY(-21px)
    margin-left: auto
    margin-right: auto
