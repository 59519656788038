@font-face
  font-family: 'Nunito'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: local(''), url('../fonts/nunito-v16-latin-regular.woff2') format('woff2')

@font-face
  font-family: 'Nunito'
  font-style: normal
  font-weight: 700
  font-display: swap
  src: local(''), url('../fonts/nunito-v16-latin-700.woff2') format('woff2')

