.title-box
  background-color: #fff
  display: block
  margin-top: -13vh !important
  position: relative
  z-index: 1

.title-box-dropshadow
  height: 13vh
  box-shadow: 1em -1em 1em rgba(#000, 0.1)
  z-index: -10
  position: absolute
  width: 100%

#has-background
  background-attachment: fixed
  background-size: cover

