@import 'styles'

.col-block
  +until($tablet)
    padding-left: 3rem
    padding-right: 3rem

  +from($tablet)
    padding-left: 0.5rem
    padding-right: 0.5rem

  +from($desktop)
    padding-left: 1.5rem
    padding-right: 1.5rem

  +from($widescreen)
    padding-left: 3rem
    padding-right: 3rem

.txt-block
  +until($tablet)
    margin-left: 3rem
    margin-right: 3rem

  +from($tablet)
    margin-left: 0.5rem
    margin-right: 0.5rem

  +from($desktop)
    margin-left: 1.5rem
    margin-right: 1.5rem

  +from($widescreen)
    margin-left: 3rem
    margin-right: 3rem

