// fonts
$family-sans-serif: 'Nunito', sans-serif

// colors
$blue: #4179CE
$black: #000000

$primary: #3457A6
$primary-light: #f9cd30
$primary-dark: #f06f00

$link: $black
$success: $primary-light

$info-light: #ffa778
$info: #e49bcf
$info-dark: #8daadf

$body-background-color: #ffffff

// forms
$control-border-width: 2px
$input-border-color: transparent
$input-background-color: #f9f9f9
$input-shadow: none

// sizes
$size-1: 7rem
$gap: 128px

// navbar
$navbar-height: 4rem
$navbar-item-img-max-height: 100px

// divider
$divider-margin-inner-size: 0px

// image dimensions
$dimensions: 16 24 32 48 64 96 128 256 512

// standard spacing shortcuts + more spacing
$spacing-shortcuts: ("margin": "m", "padding": "p")
$spacing-horizontal: "x"
$spacing-vertical: "y"
$spacing-values: ("0": 0, "1": 0.25rem, "2": 0.5rem, "3": 0.75rem, "4": 1rem, "5": 1.5rem, "6": 3rem, "7": 6rem, "8": 12rem)

