#doughnut-clip {
  clip-path: url(#doughnut-path);
  -webkit-clip-path: url(#doughnut-path);
  filter: url(#blue-filter);
}

#building-clip {
  clip-path: url(#building-path);
  -webkit-clip-path: url(#building-path);
  filter: url(#blue-filter);
}

#heart-clip {
  clip-path: url(#heart-path);
  -webkit-clip-path: url(#heart-path);
  filter: url(#blue-filter);
}

#arrow-clip {
  clip-path: url(#arrow-path);
  -webkit-clip-path: url(#arrow-path);
  filter: url(#blue-filter);
}

#tether-item-parent {
  position: relative;
  visibility: hidden;
}

@media only screen and (min-width: 768px) {
  #tether-item {
    visibility: visible;
    position: absolute;
    top: 50%;
    width: 99%;
    border-top: 2px dashed rgba(52,87,166,1);
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 768px) {
  #tether-item-mobile {
    visibility: visible;
    position: absolute;
    height: 99%;
    left: 50%;
    border-left: 2px dashed rgba(52,87,166,1);
    box-sizing: border-box;
  }
}

