@import 'styles'

//.is-square
  //box-shadow: 5px 10px
  //border-style: solid
  //border-width: 1px
  //border-color: #000

.image
  @extend .image
  &.is-svg
    display: block
    margin-left: auto
    margin-right: auto
    min-width: 150px
    max-width: 200px

.blockquote
  color: #1a1a1a
  font-style: italic
  line-height: 1.8em
  position: relative
  transition: .2s border ease-in-out
  z-index: 0
  &:before
    content: ""
    position: absolute
    top: 50%
    height: 2em
    background-color: $body-background-color
    width: 5px
    margin-top: -1em
  &:after
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' aria-hidden='true' focusable='false' width='1em' height='1em' style='-ms-transform: rotate(360deg) -webkit-transform: rotate(360deg) transform: rotate(360deg)' preserveAspectRatio='xMidYMid meet' viewBox='0 0 512 512'%3E%3Cpath d='M96.4 416h77.1l50.9-96.6V96h-160v223.4h77.1L96.4 416zm224 0h77.1l50-96.6V96H288.4v223.4h82l-50 96.6z' fill='%23f06f00'/%3E%3C/svg%3E")
    position: absolute
    top: 50%
    color: $primary
    font-style: normal
    line-height: 1em
    text-align: center
    width: 1em
    margin-top: -0.5em
    transition: .2s all ease-in-out, .4s transform ease-in-out
  &.format:before, &.format:after
    display: none
  &:active:after
    transform: rotateY(360deg)
  +until($tablet)
    border-top: 3px solid $primary-dark
    &:before
      top: 0
      left: calc(50% - 1em)
      width: 2em
    &:after
      top: 0
      left: calc(50% - 0.5em)


  p
    +until($tablet)
      margin: 2rem
    +from($tablet)
      margin: 0 2rem
    &.cite
      display: block
      font-size: 0.75em
      line-height: 1.8em
      margin-top: 1em

.blockquote-left
  @extend .blockquote
  +from($tablet)
    border-left: 3px solid $primary-dark
    &:before
      left: -4px
    &:after
      text-indent: -2px
      left: -0.5em

.blockquote-right
  @extend .blockquote
  +from($tablet)
    border-right: 3px solid $primary-dark
    &:before
      right: -4px
    &:after
      text-indent: 2px
      right: -0.5em


