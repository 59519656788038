@import 'styles'

.vtab
  @extend .panel

.vtab-block-multiline
  @extend .panel-block
  flex-direction: column
  align-items: initial
  height: 150px

  &.is-active
    border-left: 5px solid $primary
    box-shadow: 1em 0 0 0 $white

.key-takeaway
  margin: 1rem 1.75rem
  height: 80%

.case-link
  position: absolute
  right: 10px
  bottom: 10px
  a
    &:focus,
    &:focus-within,
    &:hover
      color: bulmaDarken($primary, 5%) !important

.case-link-mobile
  padding-top: 2rem
  a
    &:focus,
    &:focus-within,
    &:hover
      color: bulmaDarken($primary, 5%) !important

