body {
  /* https://github.com/jgthms/bulma/issues/449 */
  overflow-x: hidden;
}

@media only screen and (max-width: 768px) {
  #no-pr-mobile {
    padding-right: 0rem !important;
  }

  #no-px-mobile {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}

.anchor {
    display: block;
    position: relative;
    top: -3rem;
    visibility: hidden;
}

/* hide scrollbar */
.no-wrap::-webkit-scrollbar {
  display: none;
}

.no-wrap {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
